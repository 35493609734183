module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pokoje w Starej Karczmie","short_name":"Pokoje w Starej Karczmie","start_url":"/","background_color":"#000000","theme_color":"#000000","display":"standalone","icon":"src/images/custom/logo-favicon.png","cache_busting_mode":"none","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":null},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PCVZC8F","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/infosoft4netpl/domains/deployer/pokojewstarejkarczmie.pl/public_nodejs/src/intl","languages":["pl","en"],"defaultLanguage":"pl","redirect":true,"redirectComponent":"/home/infosoft4netpl/domains/deployer/pokojewstarejkarczmie.pl/public_nodejs/src/components/redirect.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["FreightNeo Pro Medium","FreightNeo Pro Book","FreightNeo Pro Bold"],"urls":["/fonts/fonts.css"]},"google":{"families":["Lato:400,700,900&display=swap&subset=latin-ext"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"383786406768108"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
